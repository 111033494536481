import Header  from './components/header/Header';
import Slider from './components/slider/Slider';
import Offers from './components/offers/Offers';
import About from './components/about/About';
import Testimonials from './components/testimonials/Testimonials';
import Services from './components/services/Services';
import Instagram from './components/instagram/Instagram';
import Contact from './components/contact/Contact';
import Footer from './components/footer/Footer';
import Closed from './components/closed/Closed';

function App() {
  return (
    <div>
      <Header />
      <main className='main'>
        <Slider />
        <Closed />
        <Offers />
        <About />
        <Services />
        <Testimonials />
        <Instagram />
        <Contact />
      </main>
      <Footer />
    </div>
  );
}

export default App;
